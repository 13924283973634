
.resources-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 20px;
}
.select-btn {
  i {
    font-size: 12px;
    margin-right: 5px;
  }
}
